import { $$ } from './utils';
const matrikaReachGoals = () => {
    const ymId = 56313895;
    const triggers = ['productCardClick', 'triggerFancyOrderForm', 'formSubmitBtn', 'showSearchField'];
    triggers.forEach(triggerName => {
        $$(`.${triggerName}`).forEach((elt) => {
            elt.on('click', () => {
                console.log({ 'ymId': ymId, 'reachGoal': triggerName });
                // debugger;
                window.ym(ymId, 'reachGoal', triggerName);
            });
        });
    });
    // in submiter.ts
    // window.ym(56313895,'reachGoal', 'formSent');
    // const objectActionBtn = $('#objectActionBtn');
    // const objectActionBtnReachGoal = () => {
    //     window.ym(ymId,'reachGoal','objectActionBtn');
    //     window.gtag('event', 'Click', {
    //         'event_category' : 'Product_action_btn',
    //         'event_label' : 'Получить консультацию'
    //     })
    //     return true;
    // }
    // objectActionBtn && objectActionBtn.on('click', objectActionBtnReachGoal);
    // const orderFormSubmitBtn = $('#orderFormSubmitBtn');
    // const orderFormSubmitBtnReachGoal = () => {
    //     window.ym(ymId,'reachGoal','orderFormSubmitBtn');
    //     window.gtag('event', 'Click', {
    //         'event_category' : 'Webform_send',
    //         'event_label' : 'Отправить'
    //     })
    //     return true;
    // }
    // orderFormSubmitBtn && orderFormSubmitBtn.on('click', orderFormSubmitBtnReachGoal);
    // Клик по тизеру "Подобрать технику"
    // const teaserActionBtn = $('#teaserActionBtn');
    // const teaserActionBtnReachGoal = () => {
    //     window.ym(ymId,'reachGoal','teaserBtn');
    //     return true;
    // }
    // teaserActionBtn && teaserActionBtn.on('click', teaserActionBtnReachGoal);
    // Открыть калькулятор
    // const openCalcBtn = $('#openCalc');
    // const openCalcReachGoal = () => {
    //     window.ym(ymId,'reachGoal','openCalc');
    //     return true;
    // }
    // openCalcBtn && openCalcBtn.on('click', openCalcReachGoal);
    // Открыть форму заказа
    // const openOrderForm = $('#openOrderForm');
    // const openOrderFormReachGoal = () => {
    //     window.ym(ymId,'reachGoal','openOrderForm');
    //     return true;
    // }
    // openOrderForm && openOrderForm.on('click', openOrderFormReachGoal);
    // Открыть форму консультации
    // const openConsultForm = $('#openConsultForm');
    // const openConsultFormReachGoal = () => {
    //     window.ym(ymId,'reachGoal','openConsultForm');
    //     return true;
    // }
    // openConsultForm && openConsultForm.on('click', openConsultFormReachGoal);
};
export default matrikaReachGoals;
