import { addTextToFormInput } from './forms';
const productOrderSum = () => {
    const props = document.querySelector('[data-element="option_props"]');
    if (!props)
        return false;
    const prodArtElement = document.querySelector('.object__artikul');
    const prodArt = prodArtElement.dataset.artikul;
    const showOrderInfoAtProdPage = () => {
        const propsData = collectPropsData(props);
        const orderText = collectOrderText(prodArt, propsData);
        addOrderTextToPage(orderText);
        addTextToFormInput('[name = "data[new][order]"]', orderText.orderProps);
    };
    props.addEventListener('input', showOrderInfoAtProdPage);
    showOrderInfoAtProdPage();
};
const collectPropsData = (props) => {
    const propsData = [];
    const radios = props.querySelectorAll('input');
    radios.forEach((radio) => {
        if (radio.checked) {
            const radioData = {
                title: radio.dataset.title,
                sign: radio.dataset.sign,
                value: radio.dataset.value,
                name: radio.dataset.name,
            };
            propsData.push(radioData);
        }
    });
    return propsData;
};
const addOrderTextToPage = (data) => {
    const summary = document.querySelector('[data-component="product-order-sum"]');
    if (!summary)
        return false;
    summary.classList.remove('hide');
    const sumName = summary.querySelector('[data-element="articul"]');
    const sumProps = summary.querySelector('[data-element="props"]');
    sumName.innerHTML = data.orderName;
    sumProps.innerHTML = data.orderProps;
};
const collectOrderText = (art, props) => {
    let orderName;
    let orderProps = '';
    props.forEach((prop, index) => {
        let propTextForName = prop.sign === '' ? prop.name : ` ${prop.sign}`;
        index === 0 ? orderName = `${prop.sign} ${art}` : orderName += ` ${propTextForName}`;
        let propText = prop.sign === '' ? prop.name : `${prop.sign} – ${prop.value}`;
        orderProps += `<span>${prop.title}:&nbsp;<span class="ff-bold">${propText}</span></span>;&nbsp;&nbsp;&nbsp;`;
    });
    return { orderName, orderProps };
};
export default productOrderSum;
