import "./polyfills";
import searchForm from './searchForm';
import accordion from './accordion';
import tabs from './tabs';
import matrikaReachGoals from './matrikaReachGoals.ts';
import {initFormValidate, StepsForm, initAntiSpam, addSourcePageToForm, submiter, saveFormInfo, addMarketingInfo} from './forms';
import trunk from './trunk.ts';
import {startCounters} from './eMarket';
import fancy from './fancy.ts';
import productOrderSum from './productOrderSum.ts';
import headeerSticky from './headeerSticky.ts';

window.addEventListener('DOMContentLoaded', function(){

	headeerSticky('.header');
	trunk();
	matrikaReachGoals();
	tabs('tabs__nav', 'tabs__body');
	searchForm('.header__search-form', '.header__search-icon', '[data-component="header-menu"]', '.header__search-icon-close');
	// cardClick('[data-component="cards"]');
	accordion('[data-component="accordion"]');
	startCounters('[data-element="field_number"]');
	// productPrice();

	productOrderSum();
 
// Валидация форм
	if(document.querySelector('[data-component="steps_form"]')){
		const stepsForm = new StepsForm({
			formSelector: '[data-component="steps_form"]',
			stepsSelector: '.fields',
			nextBtnSelector: '#nextBtn',
			prevBtnSelector: '#prevBtn'
		});
		stepsForm.init();
	}

	initFormValidate('.form', ['form_search', 'm-form_search', 'filter', 'registrate', 'auth', 'forget']);
	initAntiSpam('.form', ['form_search', 'm-form_search', 'filter', 'registrate', 'auth', 'forget']);
	addSourcePageToForm('.sourcePage');
	saveFormInfo();
	addMarketingInfo();


	// Fancy
	const selectors = [
		{trigger: 'triggerFancyConsultForm', form: 'consult_form'},
		{trigger: 'triggerFancyOrderForm', form: 'order_form'},
		{trigger: 'triggerCallbackForm', form: 'triggerCallbackForm'},
	]
	fancy(selectors)

});