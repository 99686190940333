import { getCookie } from '../utils';
export default function addMarketingInfo() {
    const fields = document.querySelectorAll('textarea[name="data[new][marketing_info_field]"]');
    if (fields.length < 1) {
        return false;
    }
    ;
    fields.forEach(field => {
        let text = '';
        const url = document.URL;
        const queryparams = url.split('?')[1];
        const params = queryparams ? queryparams.split('&') : [];
        params && params.forEach(param => {
            text = text + param + '\n';
        });
        text = text + '_ym_d:' + getCookie('_ym_d') + '\n';
        text = text + '_ym_uid:' + getCookie('_ym_uid');
        field.value = text;
    });
}
;
