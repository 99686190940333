import StepsForm from './Stepsform.ts';
import {initFormValidate} from './formValidate';
import {FormValidate} from './formValidate';
import {initAntiSpam} from './antiSpam';
import addSourcePageToForm from './addSourcePageToForm.ts';
import submiter from './submiter.ts';
import addTextToFormInput from './addTextToFormInput.ts';
import saveFormInfo from './saveFormInfo.ts';
import addMarketingInfo from './addMarketingInfo.ts';

export {
    StepsForm,
    initFormValidate,
    FormValidate,
    initAntiSpam,
    addSourcePageToForm,
    submiter,
    addTextToFormInput,
    saveFormInfo,
    addMarketingInfo
}